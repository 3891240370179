// 关于电子围栏信息表接口
import http from "./http";
import path from "./path";
export default {
    all(query={}) {  
        return http.post(path.riskElect + 'listByPage',query)
    },
    one(id={}) { 
        return http.post(path.riskElect + 'getById',id) 
    },
    add(data={}) {
        return http.post(path.riskElect + 'add',data)
    },
    edit(data={}) {
        return http.post(path.riskElect + 'updateById',data) 
    },
    list(data={}) {
        return http.post(path.riskElect + 'list',data)
    },
    alldel(id={}) {
        return http.post(path.riskElect + 'delBatch',id)
    },
    listCars(id={}) {
        return http.post(path.riskElect + 'listCars',id)
    },
    
    // // 地图
    // updBthById(id=[]){
    //     return http.post(path.riskElect + 'updBthById',id)
    // },
}