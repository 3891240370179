import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import { Message } from 'element-ui'

Vue.use(VueRouter)

export const staticRouterMap = [
  {
    // 登录
    path: '/Login',
    name: 'Login',
    component: Login
  },
]
export const governmentRouterMap = [
  {
    // Main主页面
    path:'/Main',
    name:'Main',
    meta:{isShow:0,title:'主页'},
    redirect:{name:'Monitor'},
    component: ()=> import('../views/Main.vue'),
    children:[
      {
        // 首页......实时监控
        path:'Monitor',
        name:'Monitor',
        meta:{isShow:0,title:'实时监控',icon:'el-icon-aliwuliuguiji',routeName:'Monitor'},
        component:resolve => require(['@/views/Monitor.vue'],resolve)
      },
      {
        // 首页......车辆管理
        path:'VehicleRun',
        name:'VehicleRun',
        meta:{isShow:0,title:'车辆管理',icon:'el-icon-alicheliangguanli',routeName:'VehicleRun'},
        component:resolve => require(['@/views/VehicleRun.vue'],resolve)
      },
      {
        // 首页......电子围栏
        path:'ElectronicFence',
        name:'ElectronicFence',
        meta:{isShow:0,title:'电子围栏',icon:'el-icon-alidianziweilan',routeName:'ElectronicFence'},
        component:resolve => require(['@/views/ElectronicFence.vue'],resolve)
      },
      {
        // 首页......电子围栏.....新建电子围栏
        path:"AddElectronicFence",
        name:"AddElectronicFence",
        meta:{isShow:1,title:'新建电子围栏',routeName:'ElectronicFence'},
        component:resolve => require(['@/views/AddElectronicFence.vue'],resolve),
      },
      {
        // 首页......电子围栏......编辑电子围栏
        path:"EditElectronicFence",
        name:"EditElectronicFence",
        meta:{isShow:1,title:'编辑电子围栏',routeName:'ElectronicFence'},
        component:resolve => require(['@/views/EditElectronicFence.vue'],resolve)
      },
      {
        // 首页......报警记录
        path:'AlarmRecord',
        name:'AlarmRecord',
        meta:{isShow:0,title:'报警记录',icon:'el-icon-alibaojingjilu1',routeName:'AlarmRecord'},
        component:resolve => require(['@/views/AlarmRecord.vue'],resolve)
      },
      {
        // 首页......报警记录......报警线路回放详情
        path:"details",
        name:"details",
        meta:{isShow:1,title:'报警线路回放详情',routeName:'AlarmRecord'},
        component:resolve => require(['@/views/details.vue'],resolve)
      },
      {
        // 首页......设备绑定
        path:'DeviceBinding',
        name:'DeviceBinding',
        meta:{isShow:0,title:'设备绑定',icon:'el-icon-alizhongduanguanli',routeName:'DeviceBinding'},
        component:resolve => require(['@/views/DeviceBinding.vue'],resolve)
      },
      // {
      //   // 首页......排除点管理
      //   path:'Exclude',
      //   name:'Exclude',
      //   meta:{isShow:0,title:'排除点管理',icon:'el-icon-aliweihu',routeName:'Exclude'},
      //   component:resolve => require(['@/views/Exclude.vue'],resolve)
      // },
      // {
      //   // 首页......测试
      //   path:'demo',
      //   name:'demo',
      //   meta:{isShow:0,title:'测试',icon:'el-icon-alizhongduanguanli',routeName:'demo'},
      //   component:resolve => require(['@/views/demo.vue'],resolve)
      // },
      // {
      //   // 首页
      //   path:'Mindex',
      //   name:'Mindex',
      //   meta:{isShow:0,title:'首 页',icon:'el-icon-alishouye'},
      //   redirect:{name:'MainPage'},
      //   component:()=>import('../views/MindexView.vue'),
      //   children:[
      //     {
      //       // 首页......仪表盘
      //       path:'MainPage',
      //       name:'MainPage',
      //       meta:{isShow:0,title:'仪表盘',icon:'el-icon-alishouye',routeName:'MainPage'},
      //       component:resolve => require(['@/views/MainPage.vue'],resolve)
      //     },
      //   ]
      // },
    ]
  }
]
export const platformRouterMap = [
  {
    // Main主页面
    path:'/Main',
    name:'Main',
    meta:{isShow:0,title:'主页'},
    redirect:{name:'Terminal'},
    component: ()=> import('../views/Main.vue'),
    children:[
      {
        // 首页......终端管理
        path:'Terminal',
        name:'Terminal',
        meta:{isShow:0,title:'终端管理',icon:'el-icon-alizhongduanguanli',routeName:'Terminal'},
        component:resolve => require(['@/views/Terminal.vue'],resolve)
      },
      {
        // 首页......用户管理
        path:'userList',
        name:'userList',
        meta:{isShow:0,title:'用户管理',icon:'el-icon-aliyaoqing',routeName:'userList'},
        component:resolve => require(['@/views/userList.vue'],resolve)
      },
    ]
  }
]



//初始化路由表
export const createRouter = () => new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes:staticRouterMap
});

// 原始路由表
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes:staticRouterMap
})

router.beforeEach((to, from, next) => {
  var Authorization = sessionStorage.getItem('Authorization');
      if(Authorization || to.name =='Login'){
        next();
      }else{
        Message({
          type:'warning',
          duration:1,
        })
          next({name:'Login'})
      }
})
export default router
