// 关于报警记录表接口
import http from "./http";
import path from "./path";
export default {
    all(query={}) {  
        return http.post(path.riskAlarm + 'listByPage',query)
    },
    one(id={}) { 
        return http.post(path.riskAlarm + 'getById',id)
    },
}