// 关于车辆信息表接口
import http from "./http";
import path from "./path";
export default {
    allData(id={}) { 
        return http.post(path.cfence + 'list',id)
    },
    add(data={}) {
        return http.post(path.cfence + 'add',data)
    },
    edit(data={}) {
        return http.post(path.cfence + 'updateById',data) 
    },
    one(id={}){
        return http.post(path.cfence + 'getById',id)
    },
    del(id={}) {
        return http.post(path.cfence + 'deleteById',id)
    },
}