// 用户管理信息表接口
import http from "./http";
import path from "./path";

export default {
    all(query={}) {  
        return http.post(path.User + 'listByPage',query)
    },
    one(id={}) { 
        return http.post(path.User + 'getById',id)
    },
    add(data={}) {
        return http.post(path.User + 'addCarAccount',data)
    },
    edit(data={}) {
        return http.post(path.User + 'updCarAccount',data) 
    },
    del(id=[]) {
        return http.post(path.User + 'delBatch',{ids:id})
    },
    list(data={}) {
        return http.post(path.User + 'listCarAccount',data) 
    },
    // 重置密码
    resetPwd(data={}) {
        return http.post(path.User + 'resetPwd',data) 
    },
}