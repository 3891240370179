import Vue from 'vue'
import App from './App.vue'
// import 'u-reset.css'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import '../theme/index.css'
import BaiduMap from 'vue-baidu-map'
import api from './api'
import '../src/assets/icon/iconfont.css'
// 粒子
import VueParticles from 'vue-particles'
Vue.use(VueParticles)

import Filters from './filters'
Vue.use(Filters)


// Vue.use(bmaplib)
Vue.config.productionTip = false;
Vue.prototype.api = api
Vue.use(ElementUI)
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'BSML03NwwdS9p4bOmxYuditAOhbCmVVo'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
