//聚合文件

import imglogin from './imglogin'
import login from './login'
import updatePwd from './updatePwd'
import riskCar from './riskCar'
import riskElect from './riskElect'
import riskAlarm from './riskAlarm'
import Terminal from './Terminal'
import User from './User'
import upPwd from './upPwd'
import yinYan from './yinYan'
import cfence from './cfence'





export default{
    updatePwd,      // 物流轨迹
    imglogin,       // 获取图形验证码
    login,          // 账号登录
    riskCar,        // 车辆信息表
    riskElect,      // 电子围栏信息表
    riskAlarm,      // 报警记录表
    Terminal,       // 终端信息表
    User,           // 用户管理信息表
    upPwd,          // 修改密码
    yinYan,         // 鹰眼路绑
    cfence,         // 排除点围栏表
}