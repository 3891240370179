<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data(){  
    return {
      lastTime: null,               // 最后一次点击的时间
      currentTime: null,            // 当前点击的时间
      timeOut: 360 * 60 * 1000      // 设置超时时间:30分钟
    }
  },
  created () {
    let levle = sessionStorage.getItem('level');
    if(this.$store.state.addRoutes.length <= 0 && this.$route.name !== 'Login'){
      // 企业路由刷新
      if(levle == 1){
        this.$store.dispatch("asynSetgoverRoutes");
       
      }else if(levle == 2){
        this.$store.dispatch("asynSetplatformRouter");
      }
    }
  },
 
}
</script>
<style lang="less">
// #app{
//   width: 100%;
//   height: 100vh;
//   overflow: hidden;
// }
* {
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: #e4e4e4;
  border-radius: 60px;
}

// 报警样式
.el-notification__content {
  margin-top:30px
}
.notify-warning {
//   background-image: url("../assets/baojing_box_red.png") !important;
  background-size: 100% 100% !important;
  width: 540px !important;
  height: auto !important;
  background-position-y: 0px !important;
  background-color: #FBEDED !important;
  
  margin-top: 50px !important;
  margin-right: 80px !important;
  // border: none !important;
  border:1px solid #EB5757 !important;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 0) !important;
  color: #fff;
  font-size: 14px;
  
}
.el-notification__group {
  width: 100%;
  margin-left: 160px !important;
}
.alertTile_1 {
  position: absolute !important;
  background-image: url("../src/assets/alert_red_3.png") !important;
  width: 128px !important;
  height: 128px !important;
  left: 20px !important;
  top: 30px !important;
  border-radius: 64px !important;
  color: white !important;
  line-height: 128px !important;
  text-align: center !important;
  position: relative;
  font-size: 16px !important;
  
}
.alertTile {
  position: absolute !important;
  background-image: url("../src/assets/alert_red_1.png") !important;
  width: 128px !important;
  height: 128px !important;
  left: 20px !important;
  top: 30px !important;
  border-radius: 64px !important;
  color: white !important;
  line-height: 128px !important;
  text-align: center !important;
  position: relative;
  -webkit-transform: rotate(360deg);
  animation: myfirst 3s linear infinite;
  -moz-animation: myfirst 3s linear infinite;
  -webkit-animation: myfirst 3s linear infinite;
  -o-animation: myfirst 3s linear infinite;
  font-size: 16px !important;
}

@-webkit-keyframes myfirst {
  from {
    -webkit-transform: rotate(360deg);
  }

  to {
    -webkit-transform: rotate(0deg);
  }
}
.el-notification__closeBtn{
  top:25px
}
</style>
