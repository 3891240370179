// 关于车辆信息表接口
import http from "./http";
import path from "./path";
export default {
    all(query={}) {  
        return http.post(path.riskCar + 'listByPage',query)
    },
    allData(id={}) { 
        return http.post(path.riskCar + 'list',id)
    },
    add(data={}) {
        return http.post(path.riskCar + 'add',data)
    },
    edit(data={}) {
        return http.post(path.riskCar + 'updateById',data) 
    },
    one(id={}){
        return http.post(path.riskCar + 'getById',id)
    },
    del(id=[]) {
        return http.post(path.riskCar + 'delBatch',{ids:id})
    },

    // 车辆批量开启关闭报警
    closeAlarmBatch(data=[]){
        return http.post(path.riskCar + 'closeAlarmBatch',data)
    },
    // 车辆解绑
    updBthById(data={}){
        return http.post(path.riskCar + 'unbindCar',data)
    },
    // 车辆绑定
    bindCar(data={}){
        return http.post(path.riskCar + 'bindCar',data)
    },
    // 车辆批量绑定
    updateBatch(data=[]){
        return http.post(path.riskCar + 'bindFence',data)
    },
    // 车辆批量解绑（围栏）
    unBindFence(data={}){
        return http.post(path.riskCar + 'unBindFence',data)
    },

}