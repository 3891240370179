// 接口地址
//process.env.NODE_ENV   Vue打包的环境   192.168.0.215:8765   119.45.238.116:8766
var env = "";
if(env == "development"){  
    var baseUrl = "http://119.45.238.116:8766"          // 开发环境  
    var newbaseUrl = "http://119.45.238.116:8768"
}else{
    // https://scm.coalcloudtech.com/    saas
    var baseUrl = "saas"                                // 生产环境
    // https://scm.coalcloudtech.com/distrisk/    risk
    var newbaseUrl = "risk"                             // 生产环境  
}

var updatePwd = '/saas-jt808/riskVehicleLocation/';                             // 地图轨迹接口
var riskCar = '/saas-jt808/riskCar/';                                           // 车辆信息表
var riskElect = '/saas-jt808/riskElectronicFence/';                             // 电子围栏信息表
var riskAlarm = '/saas-jt808/riskAlarm/';                                       // 报警记录表
var Terminal = '/saas-jt808/riskTerminal/';                                     // 终端信息表
var cfence = '/saas-jt808/riskExcFence/';                                       // 排除点围栏表

var User = '/saas-auth/chainAuthUser/';                                         // 用户管理信息表



export default {
    upPwd: baseUrl + '/saas-auth/chainAuthUser/updatePwd',                      // 角色权限...修改密码
    imglogin: baseUrl + '/saas-auth/code/image',                                // 验证码图形接口 /distrisk/saas
    login: baseUrl + '/saas-auth/',                                             // 登录接口     /distrisk/saas
    User: baseUrl + User,                                                       // 用户管理信息表
    updatePwd: newbaseUrl +  updatePwd,                                         // 地图轨迹接口
    riskCar: newbaseUrl +  riskCar,                                             // 车辆信息表
    riskElect: newbaseUrl +  riskElect,                                         // 电子围栏信息表
    riskAlarm: newbaseUrl +  riskAlarm,                                         // 报警记录表
    Terminal: newbaseUrl +  Terminal,                                           // 终端信息表
    cfence: newbaseUrl +  cfence,                                               // 排除点围栏表
    
    yinYan:'https://test.coalcloudtech.com/api/v3/track/getlatestpoint?'                    // 鹰眼路绑
}