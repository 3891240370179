// 关于车辆信息表接口
import http from "./http";
import path from "./path";
export default {
    all(query={}) {  
        return http.post(path.updatePwd + 'list',query)
    },
    // carAll(query={}) {  
    //     return http.post(path.updatePwd + 'listByPage',query)
    // },
    // one(id={}) { 
    //     return http.post(path.updatePwd + 'getById',id)
    // },
    // add(data={}) {
    //     return http.post(path.updatePwd + 'add',data)
    // },
    // edit(data={}) {
    //     return http.post(path.updatePwd + 'updateById',data) 
    // },
    // del(id={}) {
    //     return http.post(path.updatePwd + 'deleteById',id)
    // },
    // alldel(id={}){
    //     return http.post(path.updatePwd + 'delBatch',id)
    // },
    // 地图
    updBthById(id=[]){
        return http.post(path.updatePwd + 'updBthById',id)
    },
}