import Vue from 'vue'
import Vuex from 'vuex'
import {staticRouterMap,governmentRouterMap,platformRouterMap,createRouter} from '../router' // 依次为静态路由表，客户端路由表，平台路由表，路由表初始化
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    routes: staticRouterMap,          // 完整路由表 this.$router.options.routes
    addRoutes: [],                    // 系统最新路由表（不分企业平台）
  },
  mutations: {
    // 客户端路由菜单
    setgoverRoutes(state,addRoutes){
      state.addRoutes = addRoutes;
      state.routes = staticRouterMap.concat(addRoutes);
    },
    // 平台端路由菜单
    setplatformRouter(state,addRoutes){
      state.addRoutes = addRoutes;
      state.routes = staticRouterMap.concat(addRoutes);
    },
  },
  actions: {
    // 客户端路由菜单
    asynSetgoverRoutes:(store)=>{
      var addRoutes = governmentRouterMap;
      store.commit('setgoverRoutes',addRoutes);
      router.matcher = createRouter().matcher;
      router.addRoutes(addRoutes);
      // router.addRoutes(afterRouteMap);
      router.options.routes = store.state.routes;
    },
    // 平台端路由菜单
    asynSetplatformRouter:(store)=>{
      var addRoutes = platformRouterMap;
      store.commit('setplatformRouter',addRoutes);
      router.matcher = createRouter().matcher;
      router.addRoutes(addRoutes);
      // router.addRoutes(afterRouteMap);
      router.options.routes = store.state.routes;
    }
  },
  modules: {
  }
})
